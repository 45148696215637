import { environment } from '../../environments/environment';

export function addGoogleAnalytics(): void {
  if (environment.googleAnalytics.enabled) {
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=UA-123029002-20';
    script1.async = true;
    script1.type = 'text/plain';
    script1.setAttribute('data-usercentrics', 'Google Analytics');

    const script2 = document.createElement('script');
    script2.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-123029002-20');
    `;
    script2.type = 'text/plain';
    script2.setAttribute('data-usercentrics', 'Google Analytics');

    const head = document.querySelector('head');
    head?.appendChild(script1);
    head?.appendChild(script2);
  }
}
