export function addCookieConsentBanner(): void {
  const script = document.createElement('script');
  script.id = 'usercentrics-cmp';
  script.src = 'https://app.eu.usercentrics.eu/browser-ui/latest/loader.js';
  script.async = true;
  script.setAttribute('data-eu-mode', 'true');
  script.setAttribute('data-settings-id', 'yeRzed0KW33JWF');
  script.addEventListener('load', () => {
    const interval = setInterval(() => {
      const doc = document.getElementById('usercentrics-root')?.shadowRoot;
      if (doc) {
        const button = doc.querySelector(`button`);
        if (button) {
          button.style.display = 'none';
          clearInterval(interval);
        }
      }
    }, 100);
  });
  const head = document.querySelector('head');
  head?.appendChild(script);

  window.addEventListener('UC_UI_INITIALIZED', () => {
    const doc = document.getElementById('usercentrics-root')?.shadowRoot;
    if (doc) {
      const style = document.createElement('style');
      style.innerText = `
        img { display: none }
        .sc-jOiSOi { display: none }
      `;
      doc.appendChild(style);
    }
  });
}
