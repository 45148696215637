import { environment } from '../../../environments/environment';

export function addPrismicPreviewScript(): void {
  if (!environment.production) {
    const script = document.createElement('script');
    script.src = 'https://static.cdn.prismic.io/prismic.js?new=true&repo=autohaus-range';
    const head = document.querySelector('head');
    head?.appendChild(script);
  }
}
