import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'prismic',
    loadChildren: () => import('./prismic/prismic.module').then(m => m.PrismicModule)
  },
  {
    path: 'fahrzeuge',
    loadChildren: () =>
      import('./vehicle-search/vehicle-search.module').then(m => m.VehicleSearchModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: 'aktionen',
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule)
  },
  {
    path: '',
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [
        0,
        180
      ]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
