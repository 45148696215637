import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import * as aos from 'aos';
import { addPrismicPreviewScript } from './prismic/utils/preview';
import { addAutoUncle } from './utils/auto-uncle';
import { addCookieConsentBanner } from './utils/cookies';
import { addGoogleAnalytics } from './utils/google-analytics';
import { addMetaPixel } from './utils/meta-pixel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    // This dependency needs to be present for scully
    private readonly idle: IdleMonitorService
  ) {}

  ngOnInit(): void {
    addCookieConsentBanner();
    addMetaPixel();
    addGoogleAnalytics();
    addPrismicPreviewScript();
    addAutoUncle();
  }

  ngAfterViewInit(): void {
    aos.init();
  }
}
