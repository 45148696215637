import { registerLocaleData, ViewportScroller } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Event, Router, Scroll } from '@angular/router';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { GalleryConfig, GALLERY_CONFIG } from 'ng-gallery';
import { LightboxConfig, LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { filter, pairwise } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PhotoswipeComponent } from './shared/components/photoswipe/photoswipe.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ScullyLibModule.forRoot(),
    PhotoswipeComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: GALLERY_CONFIG,
      useFactory: () => {
        const config: GalleryConfig = {
          imageSize: 'cover',
          thumb: false,
          counter: false,
          dots: true,
          nav: false,
          loadingStrategy: 'default'
        };
        return config;
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useFactory: () => {
        const config: LightboxConfig = {
          hasBackdrop: true,
          backdropClass: 'vehicle-search-gallery-backdrop',
          closeIcon:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" fill="#fff"/></svg>'
        };
        return config;
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');

    this.handleScrollOnNavigation();
  }

  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   *
   * Related to: https://github.com/angular/angular/issues/26744
   * From: https://gist.github.com/iffa/9c820072135d25a6372d58075fe264dd
   */
  private handleScrollOnNavigation(): void {
    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise()
      )
      .subscribe((e: Scroll[]) => {
        const previous = e[0];
        const current = e[1];
        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // Check if routes match, or if it is only a query param change
          if (
            this.getBaseRoute(previous.routerEvent.url) !==
            this.getBaseRoute(current.routerEvent.url)
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([
              0,
              0
            ]);
          }
        }
      });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split('?')[0];
  }
}
